<template>
  <Head>
    <title>Trade-in {{ $titleEnd }}</title>
    <meta property="og:title" :content="'Trade-in ' + $titleEnd" />
  </Head>
  <div class="trade-in-special">
    <main class="main">
      <div class="container">
        <div
          id="realisteWidget"
          data-widget="https://very-botsad.realiste.ai/"
        ></div>
      </div>
    </main>
  </div>
</template>

<script>
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
  },
  created() {
    this.$createRealisteWidget();
  },
};
</script>
